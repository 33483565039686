import { useState, useContext, useEffect } from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import React from 'react';
import styles from 'assets/jss/material-dashboard-pro-react/components/tasksStyle.js';
import { Row } from 'react-table';
import HoverDropdown from "@components/CustomDropdown/HoverDropdown";
import DrawerSidebar from '@components/Drawer/DrawerSidebar';
import GridContainer from '@components/Grid/GridContainer';
import GridItem from '@components/Grid/GridItem';
import { Formik } from 'formik';
import Button from '@components/CustomButtons/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleColumnCheckbox from '@components/ReactTable/ToggleColumnCheckbox';
import Typography from '@material-ui/core/Typography';
import {
    AssignPermissionToAgent,
    AssignPermissionToAgentVariables
} from 'admin/Role/types/AssignPermissionToAgent';
import { useMutation } from '@apollo/client';
import { assignPermissionsToAgentMutation } from 'admin/Role/mutations';
import clientSimulator from "utils/clientSimulator";
import useNotifier from 'hooks/useNotifier';
import { CustomAgentContext } from '../context/CustomAgentProvider';
import { handleGQLErrors } from 'utils/gqlErrors';
import { useNavigate } from 'react-router-dom';
import { itemRoutes } from '../constants';
// import { useAuth } from 'admin/auth/context/AuthProvider';
import { useLazyGetAllSubRolesQuery } from 'admin/Role/queries';
interface IProps {
    row: Row;
}

// @ts-ignore
const useStyles = makeStyles(styles);
const TableActions: React.FC<IProps> = ({ row }) => {
    // const { userPref } = useAuth()
    const [getItems, { data, loading: subRolesLoading }] = useLazyGetAllSubRolesQuery();
    const [assignPermissionsModalOpen, setAssignPermissionsModalOpen] = useState<boolean>(false)
    const [subRoleId, setSubRoleId] = React.useState<any>("")
    const classes = useStyles();
    const notify = useNotifier();
    const history = useNavigate();


    const { refetchItems } = useContext(CustomAgentContext)
    const [createItem, createItemOpts] = useMutation<
        AssignPermissionToAgent,
        AssignPermissionToAgentVariables
    >(assignPermissionsToAgentMutation, {
        client: clientSimulator,
        onCompleted: async (data) => {
            if (!data) {
                return notify({
                    status: "error",
                    text: "Something went wrong!",
                });
            }
            notify({
                status: "success",
                text: "Permissions Assigned successfully.",
            });

            if (refetchItems) await refetchItems();
            history(itemRoutes.list);
        },
        onError: (err) => {
            handleGQLErrors(notify, err);
        },
    });

    useEffect(() => {
        getItems()
        // eslint-disable-next-line
      },[])
    
      useEffect(() => {
        const distributorStaff = data?.getAllSubRoles?.page?.edges?.find(edge => 
          edge?.node?.name === "GENERAL AGENT"
        );
      
        setSubRoleId(distributorStaff?.node?._id)
      },[data])
    const permissions = {
        viewAllCustomers: ["GET_ALL_CLIENT_CUSTOMERS", "GET_SPECIFIC_CUSTOMER_OR_PERSON"],
        viewSpecificCustomer: "GET_SPECIFIC_CUSTOMER_OR_PERSON",
        createAsset: ["PAIR_ASSETACCOUNT", "ACTIVATE_ASSETACCOUNT",
            "GET_ALL_PAY_PLAN_TEMPLATES", "GET_SPECIFIC_ASSET_ACCOUNTS", "UPDATE_ASSET_ACCOUNT"],
        editAsset: ["GET_ALL_PAY_PLAN_TEMPLATES", "GET_SPECIFIC_ASSET_ACCOUNTS",
            "UPDATE_ASSET_ACCOUNT"],
        activateAsset: "ACTIVATE_ASSETACCOUNT",
        viewAllAssets: ["GET_ALL_ASSET_ACCOUNTS_FOR_CLIENT", "GET_SPECIFIC_ASSET_ACCOUNTS"],
        viewSpecificAsset: "GET_SPECIFIC_ASSET_ACCOUNTS",
        viewAllItems: ["GET_ALL_CLIENT_ITEMS", "GET_SPECIFIC_ITEM"],
        viewSpecificItem: "GET_SPECIFIC_ITEM",
        generateDaysCode: "GENERATE_DAYSCODE",
        generateFreeCode: "GENERATE_FREESCODE",
        generateResetCode: "GENERATE_RESETCODE",
        viewCodeHistory: "GET_ALL_CODE_EVENTS",
        createCustomer: "CLIENT_REGISTER_CUSTOMER",
        editCustomer: ["UPDATE_PERSON", "GET_SPECIFIC_CUSTOMER_OR_PERSON"],
        deleteCustomer: "DELETE_PERSON"
    };

    const constructPermissionsArray = (input: any, permissions: any) => {
        const result: any[] = ["GET_SPECIFIC_SUBROLE_PERMISSIONS_BY_PERMISSION_NAME", "GET_SPECIFIC_DISTRIBUTOR"];

        Object.keys(input).forEach(key => {
            if (input[key] === true && permissions[key]) {
                if (Array.isArray(permissions[key])) {
                    result.push(...permissions[key]); // Spread if it's an array
                } else {
                    result.push(permissions[key]); // Push single value
                }
            }
        });

        return result;
    };


    const handleSubmit = (values: any) => {
        const result = constructPermissionsArray(values, permissions)

        createItem({
            variables: {
                assignPermissionToAgentInput: {
                    agentId: row.values["node._id"],
                    subRoleId: subRoleId,
                    permissions: result
                }
            }
        })
    }
    return (
        <div className='actions-right' style={{ display: 'flex' }}>
            <>
                <div className={`${classes.iconWrapper} ${(true) ? classes.iconWrapperHover : ''}`}>
                    <Tooltip
                        title="Delete"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                    >
                        <IconButton
                            id="expander"
                            aria-label="Delete"
                            className={classes.tableActionButton}
                            style={(true) ? { opacity: 0.3, pointerEvents: "none" } : {}}
                        >
                            <Delete
                                className={classes.tableActionButtonIcon + " " + classes.delete}
                            />
                        </IconButton>
                    </Tooltip>
                    {(true) && (
                        <span className={classes.stopSign} role="img" aria-label="Stop">
                            🚫
                        </span>
                    )}
                </div>
                <div className={`${classes.iconWrapper} ${(true) ? classes.iconWrapperHover : ''}`}>
                    <Tooltip
                        id='expander'
                        title='Edit'
                        placement='top'
                        classes={{ tooltip: classes.tooltip }}

                    >
                        <IconButton
                            id='expander'
                            aria-label='Edit'
                            className={classes.tableActionButton}
                            style={(true) ? { opacity: 0.3, pointerEvents: "none" } : {}}
                        >
                            <Edit
                                className={classes.tableActionButtonIcon + ' ' + classes.edit}
                            />
                        </IconButton>
                    </Tooltip>
                    {(true) && (
                        <span className={classes.stopSign} role="img" aria-label="Stop">
                            🚫
                        </span>
                    )}
                </div>
                <HoverDropdown
                    // isDistributor={true}
                    menus={[
                        {
                            name: "Assign Permissions",
                            action: () => {
                                setAssignPermissionsModalOpen(true)
                            },
                        },
                    ]}
                /></>
            {assignPermissionsModalOpen ? (
                <DrawerSidebar
                    isModalOpen={assignPermissionsModalOpen}
                    toggleModal={setAssignPermissionsModalOpen}
                    title={`Assign Permissions to ${row.values["node.firstName"]}`}
                    styles={{ maxWidth: "30vw", minWidth: "400px" }}
                >
                    <GridContainer>
                        <Formik onSubmit={(e) => { handleSubmit(e) }} initialValues={{}}>
                            {(formBag) => (
                                <>
                                    <GridItem sm={12}>
                                        <p style={{ backgroundColor: "#D3D3D3", padding: "0.5rem" }}>Asset Accounts</p>
                                        <FormControlLabel
                                            className={classes.formControl}
                                            control={<ToggleColumnCheckbox name="createAsset" />}
                                            label={<Typography className={classes.label}><p>Create/Edit New Asset Account</p></Typography>} />
                                        <br></br>
                                        <FormControlLabel
                                            className={classes.formControl}
                                            control={<ToggleColumnCheckbox name="viewAllAssets" />}
                                            label={<Typography className={classes.label}><p>View Asset Accounts</p></Typography>} />

                                    </GridItem>
                                    <GridItem sm={12}>
                                        <p style={{ backgroundColor: "#D3D3D3", padding: "0.5rem" }}>Items</p>
                                        <FormControlLabel
                                            className={classes.formControl}
                                            control={<ToggleColumnCheckbox name="viewAllItems" />}
                                            label={<Typography className={classes.label}><p>View Items</p></Typography>} />
                                        <br></br>
                                        <FormControlLabel
                                            className={classes.formControl}
                                            control={<ToggleColumnCheckbox name="generateDaysCode" />}
                                            label={<Typography className={classes.label}><p>Generate Day Codes for an Item</p></Typography>} />
                                        <br></br>
                                        <FormControlLabel
                                            className={classes.formControl}
                                            control={<ToggleColumnCheckbox name="generateFreeCode" />}
                                            label={<Typography className={classes.label}><p>Generate Free Codes for an Item</p></Typography>} />
                                        <br></br>
                                        <FormControlLabel
                                            className={classes.formControl}
                                            control={<ToggleColumnCheckbox name="generateResetCode" />}
                                            label={<Typography className={classes.label}><p>Generate Reset Codes for an Item</p></Typography>} />
                                        <br></br>
                                        <FormControlLabel
                                            className={classes.formControl}
                                            control={<ToggleColumnCheckbox name="viewCodeHistory" />}
                                            label={<Typography className={classes.label}><p>View Code History For Item</p></Typography>} />
                                    </GridItem>
                                    <GridItem sm={12}>
                                        <p style={{ backgroundColor: "#D3D3D3", padding: "0.5rem" }}>Customers</p>
                                        <FormControlLabel
                                            className={classes.formControl}
                                            control={<ToggleColumnCheckbox name="createCustomer" />}
                                            label={<Typography className={classes.label}><p>Create a Customer</p></Typography>} />
                                        <br></br>
                                        <FormControlLabel
                                            className={classes.formControl}
                                            control={<ToggleColumnCheckbox name="editCustomer" />}
                                            label={<Typography className={classes.label}><p>Edit a Customer</p></Typography>} />
                                        <br></br>
                                        <FormControlLabel
                                            className={classes.formControl}
                                            control={<ToggleColumnCheckbox name="deleteCustomer" />}
                                            label={<Typography className={classes.label}><p>Delete a Customer</p></Typography>} />
                                        <FormControlLabel
                                            className={classes.formControl}
                                            control={<ToggleColumnCheckbox name="viewAllCustomers" />}
                                            label={<Typography className={classes.label}><p>View Customers</p></Typography>} />


                                    </GridItem>
                                    <GridItem sm={12}>
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                formBag.handleSubmit()
                                            }}
                                            style={{ marginLeft: 12 }}
                                            disabled={createItemOpts.loading || subRolesLoading}

                                        >
                                            {createItemOpts.loading ? "Loading..." : "Save"}
                                        </Button>
                                    </GridItem>
                                </>
                            )}
                        </Formik>
                    </GridContainer>
                </DrawerSidebar>
            ) : null}
        </div>
    );
};

export default TableActions;
